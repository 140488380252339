import React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "react-bootstrap"
import { Title, Section, Text, Span } from "../../../components/Core"


const Iwrap = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }
`


const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span {
    color: #00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`

const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`

const BAcnffeCauddsesPage = () => (
  <>
    {/* <!-- Content section 1 --> */}

    <Section bg="#ffffff" py={4}>
      <Container className="pt-3 pb-3">
        <Row className="pb-4 align-items-center">
          <Col lg="12" className="order-lg-1 mt-lg-0  pb-lg-5">
            <div
              data-aos="fade-up"
              data-aos-duration="750"
              data-aos-once="true"
            >
              <div>
                <Iwrap>
                  <Itext as="h2">Excess Hair <span>London</span></Itext>
                </Iwrap>
                <SecondText>Symptoms and Causes of Excess Hair</SecondText>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="align-items-center">
          <Col lg="12">
            <Text>
              Excessive hair growth, or hirsutism, can be caused by a number of
              conditions, including:
              <br /> <br />
              Hormonal imbalances: Excess hair growth in women is frequently
              caused by hormonal imbalances, namely an excess of androgens,
              which are male hormones that are also found in women. Conditions
              such as polycystic ovarian syndrome (PCOS), adrenal gland
              abnormalities, or tumours that release androgens may contribute to
              this.
              <br /> <br />
              Genetics: Excessive hair growth can run in families and can be
              impacted by genetic factors.
              <br /> <br />
              Medications: Certain drugs, such as anabolic steroids or
              hormone-containing pharmaceuticals, may produce excessive hair
              growth as a negative effect.
              <br /> <br />
              Medical conditions: Excess hair growth can also be caused by
              medical illnesses such as Cushing's syndrome, congenital adrenal
              hyperplasia, or thyroid abnormalities.
              <br /> <br />
              Age: As women age, the hormonal balance in their bodies may vary,
              resulting in greater hair growth.
              <br /> <br />
              Ethnicity: Excess hair growth may be more common in some ethnic
              groups, such as those of Mediterranean, Middle Eastern, or South
              Asian heritage.
              <br /> <br />
              Obesity: Being overweight may be related with greater levels of
              androgens, which may contribute to excessive hair growth.
              <br /> <br />
            </Text>
          </Col>
        </Row>
        <Row>

        <Col>
        <Text>
              Excess hair growth, commonly known as hirsutism, is defined as hair growth in parts of the body where it is not normally present or where it is more apparent than usual. Excessive hair growth might cause the following symptoms:
              <br /> <br />
 

              → Hair growth on the face, chest, back, arms, legs, or pubic area that is excessive.
<br /> <br />
→ Thicker, darker, or coarser hair than typical
<br /> <br />
→ Uncontrollable or difficult-to-remove hair growth
<br /> <br />
→ Hair removal treatments such as shaving, waxing, and plucking are being used more frequently.
<br /> <br />
→ Ingrown hairs or skin discomfort caused by hair removal methods
</Text>
        </Col>
        </Row>
      </Container>
    </Section>
  </>
)

export default BAcnffeCauddsesPage
